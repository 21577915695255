@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'angleciabold';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/angleciaprodisplaybold-webfont.woff2') format('woff2'),
		url('/fonts/angleciaprodisplaybold-webfont.woff') format('woff');
}

@font-face {
	font-family: 'aeonikbold';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/aeonik-bold-webfont.woff2') format('woff2'),
		url('/fonts/aeonik-bold-webfont.woff') format('woff');
}

@font-face {
	font-family: 'aeonikmedium';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/aeonik-medium-webfont.woff2') format('woff2'),
		url('/fonts/aeonik-medium-webfont.woff') format('woff');
}

@font-face {
	font-family: 'aeonikregular';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/aeonik-regular-webfont.woff2') format('woff2'),
		url('/fonts/aeonik-regular-webfont.woff') format('woff');
}

@font-face {
	font-family: 'avenirheavy';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/Avenir-Heavy.woff2') format('woff2'),
		url('/fonts/Avenir-Heavy.woff') format('woff');
}

@font-face {
	font-family: 'avenirmedium';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/Avenir-Medium.woff2') format('woff2'),
		url('/fonts/Avenir-Medium.woff') format('woff');
}

@font-face {
	font-family: 'avenirroman';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/Avenir-Roman.woff2') format('woff2'),
		url('/fonts/Avenir-Roman.woff') format('woff');
}

@font-face {
	font-family: 'basisbold';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/basis-grotesque-bold-trial.woff2') format('woff2'),
		url('/fonts/basis-grotesque-bold-trial.woff2') format('woff2');
}

@font-face {
	font-family: 'basismedium';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/basis-grotesque-medium-trial.woff2') format('woff2'),
		url('/fonts/basis-grotesque-medium-trial.woff2') format('woff2');
}

@font-face {
	font-family: 'basislight';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/basis-grotesque-light-trial.woff2') format('woff2'),
		url('/fonts/basis-grotesque-light-trial.woff2') format('woff2');
}

body {
	@apply bg-neutral-100;
}

@layer utilities {
	.container-shadow {
		/* shadow cover top, shadow cover bottom, shadow top, shadow bottom */
		/* stylelint-disable indentation */
		background: linear-gradient(white 30%, white) center top,
			linear-gradient(white, white 70%) center bottom,
			radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), white) center
				top,
			radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), white)
				center bottom;
		/* stylelint-enable indentation */

		background-attachment: local, local, scroll, scroll;
		background-repeat: no-repeat;
		background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	}
}
